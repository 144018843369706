import * as React from 'react'
import { graphql } from 'gatsby'
import ScrollAnimation from "react-animate-on-scroll";
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Layout from '../layouts/layout'
import Title from '../components/section/title'
import Breadcrumb from '../components/section/breadcrumb'
import * as styles from '../styles/css/billing.module.css'
//  legacy page name = billing. images, strings still named billing.

const Cloud = () => {
  const { t } = useTranslation();
  const summary = {
    title: t('billingElement1Title'),
    body: t('billingElement1Body'),
    items: [
      // t('billingElement1Item1'),
      // t('billingElement1Item2'),
      // t('billingElement1Item3'),
    ]
  }
  const pivot = {
    title: t('billingElement2Title'),
    body: t('billingElement2Body'),
    items: [
      // t('billingElement2Item1'),
      // t('billingElement2Item2'),     
    ]
  }
  return (
    <Layout>
      <div className="main-content">
        <div className="rwt-split-area">
          <div className="row pb--125">
            <Breadcrumb
              title={t('billingTitle')}
              rootUrl="/"
              parentUrl="Home"
              currentUrl="Cloud"
              styles={styles.bg}
            />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mb--40">             
                <Title
                  textAlign = "text-center"
                  radiusRounded = ""
                  subtitle = ""
                  title = "Solutions"
                  description = {t('billingBody')}
                />
              </div>
            </div>
            {/* first */}
            <div className="rn-splite-style">
              <div className="split-wrapper split-right">
                <div className="row no-gutters radius-10 align-items-center">
                  <div className="col-lg-12 col-xl-6 col-12">
                    <div className="split-inner">
                      <div className="thumbnail image-left-content">
                        <img src="/images/billing/summary.jpg" alt={summary.title} />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-xl-6 col-12">
                    <div className="split-inner">
                      <ScrollAnimation 
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                        <h4 className="title">{summary.title}</h4>
                      </ScrollAnimation>
                      <ScrollAnimation 
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                        <p className="description" dangerouslySetInnerHTML={{__html: summary.body}}></p>
                      </ScrollAnimation>
                      <ScrollAnimation 
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                        <ul className="split-list">
                        {summary.items.map( (item , i) => (
                          <li>- {item}</li>
                        ))}
                        </ul>
                      </ScrollAnimation>                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Second */}
            <div className="rn-splite-style">
              <div className="split-wrapper">
                <div className="row no-gutters radius-10 align-items-center reverse">
                  <div className="col-lg-12 col-xl-6 col-12">
                    <div className="split-inner">
                      <ScrollAnimation 
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                        <h4 className="title">{pivot.title}</h4>
                      </ScrollAnimation>
                      <ScrollAnimation 
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                        <p className="description" dangerouslySetInnerHTML={{__html: pivot.body}}></p>
                      </ScrollAnimation>
                      <ScrollAnimation 
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                        <ul className="split-list">
                        {pivot.items.map( (item , i) => (
                          <li>- {item}</li>
                        ))}
                        </ul>
                      </ScrollAnimation>                     
                    </div>
                  </div>
                  <div className="col-lg-12 col-xl-6 col-12">
                    <div className="split-inner">
                      <div className="thumbnail image-left-content">
                        <img src="/images/billing/pivot.jpg" alt={pivot.title} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex-center ptb--50">
                  <a className="btn-default round" href="/offering"><span>{t('viewOffering')}</span></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export default Cloud
